// src/components/SocialMediaIcons/SocialMediaIcons.js

import React from 'react';
import { SiX, SiTelegram, SiDiscord } from '@icons-pack/react-simple-icons';
import './SocialMediaIcons.css';

const SocialMediaIcons = () => {
    return (
        <div className="social-media">
            <a href="https://x.com/ChartXchat" target="_blank" rel="noopener noreferrer" aria-label="X">
                <SiX className="icon" />
            </a>
            <a href="https://t.me/chatandchart" target="_blank" rel="noopener noreferrer" aria-label="Telegram">
                <SiTelegram className="icon" />
            </a>
        </div>
    );
};

export default SocialMediaIcons;
