import React, { useRef } from 'react';
import './App.css';
import Header from './components/Header/Header';
import TradingWidget from './components/TradingWidget/TradingWidget';
import BottomContent from './components/BottomContent/BottomContent';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
function App() {
  const chatRef = useRef(null);
  const swapperRef = useRef(null);
  const tradingRef = useRef(null);
  const headerRef = useRef(null); // Added headerRef
  const aboutRef = useRef(null); // Added headerRef

  return (
    <div className="App">
      <Header
        ref={headerRef}
        chatRef={chatRef}
        swapperRef={swapperRef}
        aboutRef={aboutRef}
      />
      <TradingWidget ref={tradingRef} />
      <BottomContent
        chatRef={chatRef}
        swapperRef={swapperRef}
        headerRef={headerRef}
        aboutRef={aboutRef}
      />

      <div ref={aboutRef}>
        <About />
      </div>
      <Footer />
    </div>
  );
}

export default App;
