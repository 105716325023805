import React, { useEffect, forwardRef } from 'react';
import './TradingWidget.css';

const TradingWidget = forwardRef((props, tradingRef) => {
    useEffect(() => {
        if (window.TradingView && tradingRef.current) {
            new window.TradingView.widget({
                width: '90vw',
                height: 610,
                symbol: 'SOLUSD',
                interval: 'D',
                timezone: 'Etc/UTC',
                theme: 'dark',
                style: '1',
                locale: 'en',
                toolbar_bg: '#f1f3f6',
                enable_publishing: false,
                allow_symbol_change: true,
                container_id: tradingRef.current.id,
            });
        }
    }, [tradingRef]);

    return <div ref={tradingRef} id="tradingview_chart" className="chartWidget"></div>;
});

export default TradingWidget;
