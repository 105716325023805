import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { io } from 'socket.io-client';
import './Chat.css';
import { Filter } from 'bad-words';
import emojilib from 'emojilib';
import { FaSmile, FaPaperPlane, FaTimes } from 'react-icons/fa';

const socket = io('https://chartandchat-314914039333.europe-west1.run.app');
const profanityFilter = new Filter();
const allEmojis = Object.keys(emojilib);

const Chat = () => {
    const [username, setUsername] = useState('');
    const [emoji, setEmoji] = useState('');
    const [chatVisible, setChatVisible] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [showGifSearch, setShowGifSearch] = useState(false);
    const [gifResults, setGifResults] = useState([]);
    const [messageTimestamps, setMessageTimestamps] = useState([]);
    const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
    const [allMessagesLoaded, setAllMessagesLoaded] = useState(false);
    const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const gifSearchRef = useRef(null);

    useEffect(() => {
        socket.emit('load messages');

        socket.on('load messages', (loadedMessages) => {
            setMessages(loadedMessages);
        });

        socket.on('chat message', (msg) => {
            setMessages((prevMessages) => [...prevMessages, msg]);
        });

        return () => {
            socket.off('load messages');
            socket.off('chat message');
        };
    }, []);

    useEffect(() => {
        if (showGifSearch) {
            const handleClickOutside = (event) => {
                if (gifSearchRef.current && !gifSearchRef.current.contains(event.target)) {
                    setShowGifSearch(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [showGifSearch]);


    useLayoutEffect(() => {
        if (chatVisible && messages.length > 0) {
            const messagesContainer = messagesContainerRef.current;
            if (messagesContainer) {
                messagesContainer.scrollTop = messagesContainer.scrollHeight;
            }
        }
    }, [chatVisible, messages]);


    // Improved scroll handling
    useEffect(() => {
        const handleScroll = () => {
            if (messagesContainerRef.current) {
                const { scrollTop } = messagesContainerRef.current;

                if (scrollTop < 30 && !loadingMoreMessages && !allMessagesLoaded) {
                    loadMoreMessages();
                }
            }
        };

        const messagesContainer = messagesContainerRef.current;
        if (messagesContainer) {
            messagesContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (messagesContainer) {
                messagesContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loadingMoreMessages, allMessagesLoaded]);



    const loadMoreMessages = () => {
        if (messages.length === 0 || loadingMoreMessages || allMessagesLoaded) return;

        const messagesContainer = messagesContainerRef.current;

        // Capture current scroll height and scrollTop
        const previousScrollHeight = messagesContainer.scrollHeight;
        const previousScrollTop = messagesContainer.scrollTop;

        setLoadingMoreMessages(true);

        const oldestTimestamp = messages[0]?.timestamp;
        socket.emit('load more messages', { timestamp: oldestTimestamp });

        socket.once('more messages', (newMessages) => {
            if (newMessages.length > 0) {
                // Prepend new messages to the existing list
                setMessages((prevMessages) => [...newMessages, ...prevMessages]);

                // Adjust scroll position after messages have been added
                setTimeout(() => {
                    const newScrollHeight = messagesContainer.scrollHeight;
                    messagesContainer.scrollTop = newScrollHeight - previousScrollHeight + previousScrollTop;
                }, 0);
            } else {
                // No more messages to load
                setAllMessagesLoaded(true);
            }
            setLoadingMoreMessages(false);
        });
    };


    const enterChat = () => {
        const randomNum = Math.floor(Math.random() * 1000);
        if (username.trim() === '') {
            setUsername(`Anonymous${randomNum}`);
        }
        setEmoji(allEmojis[Math.floor(Math.random() * allEmojis.length)]);
        setChatVisible(true);

        // Scroll to bottom when the chat becomes visible
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };


    const searchGifs = (query = 'random') => {
        const API_KEY = 'AIzaSyDO7cdS9ax6-9jib2ZakCDAvrMez0QY0JY';
        const search_url = `https://tenor.googleapis.com/v2/search?q=${query}&key=${API_KEY}&limit=10`;
        httpGetAsync(search_url, (responsetext) => {
            const response_objects = JSON.parse(responsetext);
            setGifResults(response_objects.results);
        });
    };

    const httpGetAsync = (theUrl, callback) => {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                callback(xmlHttp.responseText);
            }
        };
        xmlHttp.open("GET", theUrl, true);
        xmlHttp.send(null);
    };

    const selectGif = (gifUrl) => {
        const messageData = {
            username: username || `Anonymous${Math.floor(Math.random() * 1000)}`,
            emoji,
            message: `<img src="${gifUrl}" alt="GIF" class="chat-gif" />`,
        };
        socket.emit('chat message', messageData);
        setShowGifSearch(false);

        // Defer scrolling to ensure DOM has updated
        setTimeout(() => {
            const messagesContainer = messagesContainerRef.current;
            if (messagesContainer) {
                messagesContainer.scrollTop = messagesContainer.scrollHeight;
            }
        }, 0);
    };



    const sendMessage = (e) => {
        e.preventDefault();
        const now = Date.now();
        if (messageTimestamps.length >= 5 && now - messageTimestamps[0] < 10000) {
            alert("You're sending messages too quickly. Please wait a moment.");
            return;
        }
        if (input.trim()) {
            let filteredMessage = profanityFilter.clean(input);
            const messageData = {
                username: username || `Anonymous${Math.floor(Math.random() * 1000)}`,
                emoji,
                message: filteredMessage,
            };
            socket.emit('chat message', messageData);
            setInput('');
            setMessageTimestamps((timestamps) => [...timestamps.slice(-2), now]);
            setShouldAutoScroll(true);
            // Defer scrolling to ensure DOM has updated
            setTimeout(() => {
                const messagesContainer = messagesContainerRef.current;
                if (messagesContainer) {
                    messagesContainer.scrollTop = messagesContainer.scrollHeight;
                }
            }, 0);
        }
    };

    return (
        <div className="chatContainer">
            <h1 style={{ color: 'white' }}>Chat with other CNC enthusiasts</h1>
            {!chatVisible && (
                <div id="usernamePrompt">
                    <input
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Enter your username"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                enterChat();
                            }
                        }}
                    />
                    <button className="chat-button" id="enterChat" onClick={enterChat}>
                        Enter Chat
                    </button>
                </div>
            )}
            <div
                id="chatArea"
                style={{
                    filter: chatVisible ? 'none' : 'blur(4px)',
                    pointerEvents: chatVisible ? 'auto' : 'none',
                }}
            >
                <ul id="messages" ref={messagesContainerRef}>
                    {messages.map((msg, index) => (
                        <li key={index}>
                            <span className="emoji">{msg.emoji}</span>
                            <strong>{msg.username}: </strong>
                            <span dangerouslySetInnerHTML={{ __html: msg.message }} />
                        </li>
                    ))}
                    <div ref={messagesEndRef} />
                </ul>
                {showGifSearch && (
                    <div className="gif-search" ref={gifSearchRef}>
                        <FaTimes className="close-gif-search" onClick={() => setShowGifSearch(false)} />
                        <div className="gif-results">
                            {gifResults.map((gif) => (
                                <img
                                    key={gif.id}
                                    src={gif.media_formats?.nanogif?.url || gif.media_formats?.gif?.url}
                                    alt="GIF"
                                    onClick={() => selectGif(gif.media_formats?.gif?.url)}
                                />
                            ))}
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Search for GIFs"
                                onChange={(e) => searchGifs(e.target.value)}
                            />
                        </div>
                    </div>
                )}

                <div className="chat-input-container">
                    <form id="form" onSubmit={sendMessage}>
                        <input
                            id="input"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Remember to be polite"
                            autoComplete="off"
                        />
                        <div className="chat-button-container">
                            {input.trim() ? (
                                <FaPaperPlane
                                    className="chat-icon"
                                    color="#7289da"
                                    onClick={sendMessage}
                                />
                            ) : (
                                <FaSmile
                                    className="chat-icon"
                                    onClick={() => {
                                        setShowGifSearch(!showGifSearch);
                                        if (!showGifSearch) searchGifs();
                                    }}
                                />
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Chat;
