import React from 'react';
import { useEffect } from "react";
import './SwapWidget.css'

const Swapper = () => {
    useEffect(() => {
        // Ensure that Jupiter is loaded
        if (window.Jupiter) {
            window.Jupiter.init({
                displayMode: "integrated",  // "modal" for popup, "integrated" for inline, "widget" for button
                integratedTargetId: "integrated-terminal",  // Div where Jupiter will be embedded
                endpoint: "https://solana-mainnet.g.alchemy.com/v2/dHeO_z3M9CHjyYF8Y-fx_dynb8CULJ10",  // Your Solana RPC endpoint
                formProps: {
                    fixedOutputMint: false,  // Allow users to choose tokens to swap
                }
            });
        }
    }, []);

    return (
        <div className='swapWidget'>
            <h1 >Token available soon on Jupiter</h1>
            <div id="integrated-terminal" style={{ width: '400px' }}></div>
        </div>
    );
};


export default Swapper;
