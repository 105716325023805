import React, { forwardRef } from 'react';
import './Header.css';
import SocialMediaIcons from '../SocialMediaIcons/SocialMediaIcons';

const Header = forwardRef(({ chatRef, swapperRef, aboutRef }, headerRef) => {
    const handleScrollToChat = () => {
        if (chatRef?.current) {
            chatRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScrollToSwapper = () => {
        if (swapperRef?.current) {
            swapperRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScrollToAbout = () => {
        if (aboutRef?.current) {
            aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <header ref={headerRef}>
            <h1>CHART N CHAT</h1>
            <div className="navigation">
                <button onClick={handleScrollToChat}>Chat</button>
                <button onClick={handleScrollToSwapper}>Buy and Swap</button>
                <button onClick={handleScrollToAbout}>About Us</button>
            </div>
            <SocialMediaIcons />
        </header>
    );
});

export default Header;
