import React, { useEffect, useState } from 'react';
import Chat from '../Chat/Chat';
import Swapper from '../SwapWidget/SwapWidget';
import './BottomContent.css';
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import About from '../About/About';

const BottomContent = ({ chatRef, swapperRef, headerRef, aboutRef }) => {
    const [isHeaderInView, setIsHeaderInView] = useState(true); // State to track header visibility

    const handleScrollToHeader = () => {
        if (headerRef?.current) {
            headerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        // Intersection Observer to check if header is in view
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                setIsHeaderInView(entry.isIntersecting); // Update state based on header visibility
            },
            { threshold: 0.1 } // Trigger when 10% of the header is visible
        );

        if (headerRef?.current) {
            observer.observe(headerRef.current);
        }

        return () => {
            if (headerRef?.current) {
                observer.unobserve(headerRef.current);
            }
        };
    }, [headerRef]);

    return (
        <div className="bottomContent">
            <div ref={chatRef}>
                <Chat />
            </div>
            <div ref={swapperRef}>
                <Swapper />
            </div>
           
            {/* Floating Action Button for scrolling to header */}
            {!isHeaderInView && ( // Show button only if header is not in view
                <Fab
                    color="primary"
                    aria-label="go-up"
                    onClick={handleScrollToHeader}
                    style={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                        zIndex: 1000,
                        color: 'black',
                        backgroundColor: 'whitesmoke',
                    }}
                >
                    <ArrowUpwardIcon />
                </Fab>
            )}
        </div>
    );
};

export default BottomContent;
