
import React from 'react';
import SocialMediaIcons from '../SocialMediaIcons/SocialMediaIcons';
import myImage from '../../assets/images/logo-cnc.jpg';
import './Footer.css'

const Footer = () => {
    return (
        <footer className='footer'>
            <SocialMediaIcons />
            <div>
                <h1>CNC 2024©</h1>
            </div>
        </footer >
    );
};

export default Footer;
