import React from 'react';
import './About.css'; // Import the CSS file for styling

const About = () => {
  return (
    <div className="about-container">
      <h1>Welcome to CNC Coin</h1>
      <p className="intro">
        The only coin that brings together the fine art of charting and chatting! 🎉
      </p>
      <p>
        Born from a late-night crypto chat, CNC Coin was inspired by a simple idea: what if there
        was a coin that didn’t just connect the dots on a chart but connected the people behind the
        charts, too? Well, we took that idea, added a sprinkle of meme magic, and voila—CNC Coin was
        born!
      </p>
      <p>
        With CNC, we’re not just here to ride the waves; we’re here to chart our own course. Whether
        you're a serious trader or just in it for the laughs, CNC Coin is where you can trade, talk,
        and stay connected in real time with other legends of the crypto world.
      </p>
      <p>
        So, grab your charts, warm up your chat fingers, and let’s make the crypto journey a little
        more social—and a lot more fun. Welcome to the CNC family!
      </p>
    </div>
  );
};

export default About;
